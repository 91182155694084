const data = {
    "ombrelle": {
        "id": "ombrelle",
        "name": "ombrelle",
        "link": "http://biero.ca/ombrelle/index.html",
        "year": "2016",
        "type": "game game game game game",
        "context": "Collège de Maisonneuve",
        "team": [],
        "description": "A classic 2D platform game with a twist. While the goal is obvious - reach the end of each level - the way is awfully difficult as the elements of the course are revealed under the player's umbrella.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Game Design"
            },
            {
                "name": "Graphic Design"
            },
            {
                "name": "Hero animation"
            },
            {
                "name": "Code"
            },
            {
                "name": "Integration"
            },
            {
                "name": "Sound editing"
            }
            
        ],
        "technologies": [
            {
                "name": "Illustrator"
            },
            {
                "name": "Unity"
            },
            {
                "name": "JavaScript"
            },
            {
                "name": "Cubase"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "ombrelle",
                "name": "ombrelle-1.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "ombrelle",
                "name": "ombrelle.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "ombrelle",
                "name": "ombrelle-2.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "ombrelle",
                "name": "ombrelle-walk.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "ombrelle",
                "name": "ombrelle-end.png",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "space-square": {
        "id": "space-square",
        "name": "space square",
        "link": "/space-square-game/index.html",
        "year": "2016",
        "type": "game game game game",
        "context": "Collège de Maisonneuve",
        "team": [],
        "description": "Space Square is my take on simple yet famous games like Flappy Bird and Life Force. Cross the cube through all the white terminals that will appear on the screen to continue your way to the next level.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Game Design"
            },
            {
                "name": "Graphic Design"
            },
            {
                "name": "Code"
            },
            {
                "name": "Integration"
            },
            {
                "name": "Sound editing"
            }
        ],
        "technologies": [
            {
                "name": "Illustrator"
            },
            {
                "name": "Phaser"
            },
            {
                "name": "JavaScript"
            },
            {
                "name": "Cubase"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "space-square",
                "name": "space-square-1.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "space-square",
                "name": "space-square-2.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "space-square",
                "name": "space-square-1.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "space-square",
                "name": "space-square-2.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "space-square",
                "name": "space-square-poster.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "think-tank": {
        "id": "think-tank",
        "name": "think tank",
        "link": "http://biero.ca/think-tank/index.html",
        "year": "2018",
        "type": "game game game game",
        "context": "lg2",
        "team": [],
        "description": "Through a 'devfi' initiated by Louis-Philippe Favreau - Director of Technology at lg2 - to create a multiplayer game, I took the challenge to design and code a tribute to the glorious DOS game Scorched.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Game Design"
            },
            {
                "name": "Graphic Design"
            },
            {
                "name": "Code"
            },
            {
                "name": "Integration"
            }
        ],
        "technologies": [
            {
                "name": "Illustrator"
            },
            {
                "name": "Unity"
            },
            {
                "name": "C#"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "think-tank",
                "name": "think-tank-1.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "think-tank",
                "name": "think-tank-2.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "think-tank",
                "name": "think-tank-1.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "think-tank",
                "name": "think-tank-2.gif",
                "alt": "",
                "legend": ""
            }
            
        ]
    },
    "equilibrium": {
        "id": "equilibrium",
        "name": "equilibrium",
        "link": "http://biero.ca/equilibrium/index.html",
        "year": "2017",
        "type": "game game game game",
        "context": "Collège de Maisonneuve",
        "team": [
            {
                "name": "Mélodie Gauthier-Barthez"
            },
            {
                "name": "Noémi Legault"
            }
        ],
        "description": "Alexander Calder influenced my thoughts on composition, symmetry and balance. This game took his poetry in action has the player must balance more and more complex mobiles to obtain gravitational harmony.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Graphic Design"
            },
            {
                "name": "Game Design"
            },
            {
                "name": "3D modeling"
            },
            {
                "name": "Code"
            },
            {
                "name": "Integration"
            },
            {
                "name": "Sound editing"
            }
        ],
        "technologies": [
            {
                "name": "Unity"
            },
            {
                "name": "C#"
            },
            {
                "name": "Maya"
            },
            {
                "name": "Illustrator"
            },
            
            {
                "name": "Cubase"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "equilibrium",
                "name": "equilibrium-1.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "equilibrium",
                "name": "equilibrium-2.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "equilibrium",
                "name": "equilibrium-3.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "equilibrium",
                "name": "equilibrium-4.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "equilibrium",
                "name": "equilibrium.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "equilibrium",
                "name": "equilibrium-poster.png",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "mini-putt-91": {
        "id": "mini-putt-91",
        "name": "mini-putt 91",
        "link": "",
        "year": "2017",
        "type": "video video video video",
        "context": "Collège de Maisonneuve",
        "team": [
            {
                "name": "Julien C.-Bouchard"
            },
            {
                "name": "Noémi Legault"
            }
        ],
        "description": "This short animated film is inspired by Rocky IV, Kim Jong Un and the epic summer programming of 90s cable networks. With special thanks to Père Fouras, Kashtin and Marie-Soleil Tougas.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Scenario"
            },
            {
                "name": "3D modeling"
            },
            {
                "name": "Animation"
            },
            {
                "name": "Audio editing"
            }
        ],
        "technologies": [
            {
                "name": "Maya"
            },
            {
                "name": "Premiere"
            },
            {
                "name": "Cubase"
            }
        ],
        "video": "CaxxObQaDvs",
        "images": [
            {
                "folder": "mini-putt",
                "name": "mini-putt-91-drago.png",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "mini-putt",
                "name": "mini-putt-91-drago.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "mini-putt",
                "name": "mini-putt-91-18.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "mini-putt",
                "name": "mini-putt-91-panel.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "cest-agressant": {
        "id": "cest-agressant",
        "name": "c't'agressant",
        "link": "",
        "year": "2017",
        "type": "video video video video",
        "context": "Collège de Maisonneuve",
        "team": [
            {
                "name": "Julien C.-Bouchard"
            }
        ],
        "description": "We had too much fun with making this short clip on a Pépé et sa guitare song. We took the title to the letter: it's aggressive.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Video editing"
            },
            {
                "name": "Compositing"
            }
        ],
        "technologies": [
            {
                "name": "After Effects"
            }
        ],
        "video": "m86S5QYPLR4",
        "images": [
            {
                "folder": "cest-agressant",
                "name": "cest-agressant-1.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "cest-agressant",
                "name": "cest-agressant-2.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "cest-agressant",
                "name": "nyan-cat.jpg",
                "alt": "",
                "legend": ""
            },
        ]
    },
    "lg2": {
        "id": "lg2",
        "name": "lg2",
        "link": "https://lg2-next.fabrique2.net/fr/",
        "year": "2017-2019",
        "type": "employee employee employee employee",
        "context": "lg2",
        "team": [
            {
                "name": "GEN, thank you guys"
            }
        ],
        "description": "lg2 is an world-class agency and I'm proud to be a part of it. I work on several web projects of different scale using many up to date technologies. Some of the website I worked on include À Domicile, Pause ton écran, CCEI, Paillard and Lassonde.",
        "contribution": [
            {
                "name": "Integration"
            },
            {
                "name": "Front-end development"
            },
            {
                "name": "Maintenance"
            }
        ],
        "technologies": [
            {
                "name": "HTML 5"
            },
            {
                "name": "ITCSS"
            },
            {
                "name": "TypeScript"
            },
            {
                "name": "Twig"
            },
            {
                "name": "Craft"
            },
            {
                "name": "CMS"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "lg2",
                "name": "adomicile-menu.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "lg2",
                "name": "pause-ton-ecran.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "lg2",
                "name": "ccei.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "lg2",
                "name": "paillard-produits.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "lg2",
                "name": "lassonde-investisseurs.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "ward-associes": {
        "id": "ward-associes",
        "name": "Ward & Associes",
        "link": "https://wardetassocies.com/en/home-en/",
        "year": "2018-2019",
        "type": "website website website website",
        "context": "faitendudoux design",
        "team": [
            {
                "name": "Julien C.-Bouchard"
            }
        ],
        "description": "One of the best wine retailer in Quebec province, they wanted a friendly user website, focusing on their vast list of small and ethic winegrowers. The phase 1 is actually online as we handle this project in a progressive workflow.",
        "contribution": [
            {
                "name": "Graphic design"
            },
            {
                "name": "Admin setup"
            },
            {
                "name": "Front-end development"
            }
        ],
        "technologies": [
            {
                "name": "Wordpress (custom theme)"
            },
            {
                "name": "PHP",
            },
            {
                "name": "SCSS",
            },
            {
                "name": "JavaScript",
            },
            {
                "name": "Illustrator",
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "ward-associes",
                "name": "ward-associes-home.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "ward-associes",
                "name": "ward-associes-saq.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "pullman": {
        "id": "pullman",
        "name": "Pullman",
        "link": "https://www.pullman-mtl.com/en/home/",
        "year": "2018",
        "type": "website website website website",
        "context": "faitendudoux design",
        "team": [],
        "description": "I got the privilege to met the people at Pullman while I was working as a waiter. They needed a new website and, after a review of different alternatives, we decided to go with the CMS Space Square. Within the chosen template, my goal was to reveal their inviting and elegant space.",
        "contribution": [
            {
                "name": "Admin setup"
            },
            {
                "name": "Theme customisation"
            },
            {
                "name": "Photo editing"
            },
            {
                "name": "Integration"
            }
            
        ],
        "technologies": [
            {
                "name": "Spacesquare"
            },
            {
                "name": "CSS"
            },
            {
                "name": "JavaScript"
            },
            {
                "name": "Photoshop"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "pullman",
                "name": "pullman-home.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pullman",
                "name": "pullman-contact.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pullman",
                "name": "pullman-verre.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pullman",
                "name": "pullman-menu.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pullman",
                "name": "pullman-salles.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pullman",
                "name": "pullman-presse.jpg",
                "alt": "",
                "legend": ""
            }
            
        ]
    },
    "this-portfolio": {
        "id": "this-portfolio",
        "name": "this.portfolio",
        "link": "/",
        "year": "2019",
        "type": "website website website website",
        "context": "faitendudoux design",
        "team": [],
        "description": "My taste in design mix minimalism, abstraction and brutalism. A website is a story, I like mine to express a bold user experience within hard edge geometry and precise details ...and offbeat humor.",
        "contribution": [
            {
                "name": "Graphic Design"
            },
            {
                "name": "Front-end development"
            },
            {
                "name": "Front-end development"
            },
            {
                "name": "Contents"
            },
            {
                "name": "Integration"
            }
        ],
        "technologies": [
            {
                "name": "React"
            },
            {
                "name": "JavaScript"
            },
            {
                "name": "ITCSS"
            },
            {
                "name": "Illustrator"
            },
            {
                "name": "Photoshop"
            },
            {
                "name": "Premiere"
            },
            {
                "name": "Giphy"
            }

        ],
        "video": "",
        "images": [
            {
                "folder": "this-portfolio",
                "name": "you-are-here.gif",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "this-portfolio",
                "name": "troll.gif",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "flyers": {
        "id": "flyers",
        "name": "flyers",
        "link": "",
        "year": "2015-2019",
        "type": "graphic design graphic design graphic design graphic design",
        "context": "restauration",
        "team": [],
        "description": "I created several Instagram and Facebook flyers for events organized by restaurant friends. These are great opportunities to try different graphic approaches to illustrate the concept and details of the event.",
        "contribution": [
            {
                "name": "Concepts"
            },
            {
                "name": "Graphic Design"
            }
        ],
        "technologies": [
            {
                "name": "Illustrator"
            },
            {
                "name": "Photoshop"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "flyers",
                "name": "diplomate-mtl-au-comptoir.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "diplomate-nye-2018.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "diplomate-staniversaire.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "diplomate-ward-associes.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "diplomate-wino.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "diplomate-facebook.png",
                "alt": "Diplomate Facebook",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "pinard-restaurants.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "pinard-boutiques.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "flyers",
                "name": "ward-associes-orange.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "tes-pas-game": {
        "id": "tes-pas-game",
        "name": "t'es pas game !",
        "link": "http://tespasgame-blog.tumblr.com/",
        "year": "2013",
        "type": "event design event design event design event design",
        "context": "UQAM",
        "team": [
            {
                "name": "Hella El Khiari"
            },
            {
                "name": "Cécile Bernard"
            },
            {
                "name": "Louis-Philippe Forest-Gaudet"
            },
            {
                "name": "Floriane De Roover"
            },
            {
                "name": "Eve Lamoureux-Cyr"
            }
        ],
        "description": "For the 2013 Montreal Nuit Blanche, we created a low-tech / high-tech installation on the Allée des bouquiniste in collaboration with the BaNQ. The concept address social prohibitions where, for a night through interactive projection, festival-goers could break the hall of a mansion, annihilate a snowman or make a child cry.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Project manager"
            },
            {
                "name": "Assistant director"
            },
            {
                "name": "In situ installation"
            }
        ],
        "technologies": [
            {
                "name": "Projection"
            },
            {
                "name": "Kinect"
            },
            {
                "name": "Premiere"
            },
            {
                "name": "Photohop"
            },
            {
                "name": "Wood"
            },
            {
                "name": "Canvas"
            }
        ],
        "video": "D6oFJ1P9LqM",
        "images": [
            {
                "folder": "pas-game",
                "name": "pas-game-1.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pas-game",
                "name": "pas-game-2.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pas-game",
                "name": "pas-game-3.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pas-game",
                "name": "pas-game-4.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pas-game",
                "name": "pas-game-5.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "pas-game",
                "name": "pas-game-6.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    },
    "gabriel": {
        "id": "gabriel",
        "name": "gabriel",
        "link": "https://www.journaldemontreal.com/2012/12/25/un-plaisantin-change-le-nom-de-la-place-emilie-gamelin",
        "year": "2012",
        "type": "event design event design event design event design",
        "context": "UQAM",
        "team": [
            {
                "name": "Isabelle Raymond"
            },
            {
                "name": "Louis-Philippe Forest-Gaudet"
            }
        ],
        "description": "Like the 'Dude Chilling Park' in Vancouver, we decided to prank Montreal by renaming Émilie Gamelin Park - central location of the Printemps Érable movement - by Gabriel Nadeau-Dudois. More a reflection on urban signage and public space than a claim, the prank have been on display for several weeks.",
        "contribution": [
            {
                "name": "Concept"
            },
            {
                "name": "Design"
            },
            {
                "name": "Public mischief"
            }
        ],
        "technologies": [
            {
                "name": "Illustrator"
            },
            {
                "name": "Vinyl printing"
            }
        ],
        "video": "",
        "images": [
            {
                "folder": "gabriel",
                "name": "gabriel.jpg",
                "alt": "",
                "legend": ""
            },
            {
                "folder": "gabriel",
                "name": "gabriel-close.jpg",
                "alt": "",
                "legend": ""
            }
        ]
    }
}

export default data;