import React from 'react';

import './ProjectHeader.scss';


class Project extends React.Component {

    createList = (array) => {
        let list = []
        let arrayLength = array.length;

        for (let i = 0; i < arrayLength; i++) {
            let item = array[i].name;
            list.push(<li key={i} className="project__header__list__item">{item}</li>)
        }
        return list
    }


    render() {
        const year = this.props.content.year;
        const type = this.props.content.type;
        const context = this.props.content.context;
        const team = this.props.content.team;
        const technologies = this.props.content.technologies;
        const description = this.props.content.description;
        const contribution = this.props.content.contribution;

        return (
            <header className="project__header">
                <div className="project__header__left">
                    <p className="project__header__item">{year}</p>
                    <marquee className="project__header__item project__header__item--marquee italic">{type}</marquee>
                    <p className="project__header__item">{context}</p>
                    {team.length > 0 &&
                        <div>
                            <h3 className="project__header__subtitle">teammate{team.length > 1 && <span>s</span>}:</h3>
                            <ul>
                                {this.createList(team)}
                            </ul>
                        </div>
                    }
                </div>
                <div className="project__header__right">
                    <p>{description}</p>
                    <div className="project__header__right__lists">
                        <div className="project__header__right__list">
                            <h3 className="project__header__subtitle">contribution:</h3>
                            <ul>
                                {this.createList(contribution)}
                            </ul>
                        </div>
                        <div className="project__header__right__list">
                            <h3 className="project__header__subtitle">technologie{technologies.length > 1 && <span>s</span>}:</h3>
                            <ul>
                                {this.createList(technologies)}
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </header>
        )
    }
}

export default Project;







