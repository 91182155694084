import React from 'react';
import './BackHome.scss';

import left_arrow from '../../assets/svg/left_arrow.svg';


const BackHome = () => (
    <aside>
        <a href="/" className="back__home">
            <span className="back__home__text">home</span>
            <span className="back__home__arrow"><img src={left_arrow} alt="left arrow" /></span>
        </a>
    </aside>
);

export default BackHome;