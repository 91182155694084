import React from 'react';
import './SideMarquee.scss';


const SideMarquee = () => {
    return ( 
        <marquee direction="down" className="side__marquee">simon c_bouchard</marquee>
    )
}

export default SideMarquee;