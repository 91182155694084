import React from 'react';
import YouTube from 'react-youtube';

  
class ProjectVideo extends React.Component {

    render() {
        const opts = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
              color: 'white'
            }
        };

        return (
            <div className="project__media project__media--video">
                <YouTube
                    videoId={this.props.video}
                    opts={opts}
                    onReady={this._onReady}
                />
            </div>
        )
    }
};


export default ProjectVideo;