import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Burger from '../Burger/Burger';
import Menu from '../Menu/Menu';
import Coordinates from '../Coordinates/Coordinates';
import BackHome from '../BackHome/BackHome';
import SideMarquee from '../SideMarquee/SideMarquee';
import Home from '../Home/Home';
import ProjectDetails from '../ProjectDetails/ProjectDetails';

import './App.scss';


class App extends React.Component {  
  constructor(props) {
    super(props);
    this._toggleMenu = this._toggleMenu.bind(this)
    this._calledComponent = this._calledComponent.bind(this)
    this.state = { 
      //showHideMenu: 'menu--hidden',
      showHideMenu: '',
      componentName: null
    };
  }

  _toggleMenu() {
    /*
    let menu = (this.state.showHideMenu === 'menu--hidden') ? 'menu--shown' : 'menu--hidden';
    this.setState({'showHideMenu': menu});
    */

    let menu;
    if(this.state.showHideMenu != '') {
      menu = (this.state.showHideMenu === 'menu--hidden') ? 'menu--shown' : 'menu--hidden';
    } else {
      menu = 'menu--shown';
    }
    this.setState({'showHideMenu': menu});
    
  }

  _calledComponent(dataFromChild) {
    this.setState({ componentName: dataFromChild });
  }


  render() {
    const showHideMenu = this.state.showHideMenu;
    const componentName = this.state.componentName;
    
    return (
      <Router>
        <Route
          render={({ location }) => (
            <div className={`portfolio ${componentName}`}>

              <Burger _toggleMenu={this._toggleMenu} showHideMenu={showHideMenu} />

              <Menu _toggleMenu={this._toggleMenu} showHideMenu={showHideMenu} />

              <SideMarquee />

              <Coordinates />
             
              <section className="content">
                <TransitionGroup>
                  <CSSTransition
                    in={true}
                    appear={true}
                    key={location.key}
                    classNames="fade"
                    timeout={600}
                  >
                    <Switch location={location}>
                      <Route exact path="/" render={() => <Home _calledComponent={this._calledComponent} />}/>
                      <Route exact path="/:_id" render={() => <ProjectDetails _calledComponent={this._calledComponent} />} />
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </section>

              <BackHome />

            </div>
          )}
        />
      </Router>
    )
  }
}
//);


//toggleDivColor() {
//  var css = (this.props.showHideSidenav === "hidden") ? "show" : "hidden";
//  this.setState({"showHideSidenav":css});
//};



/*
const styles = {};

styles.fill = {
  overflow: "hidden",
  //position: "absolute",
  //left: 0,
  //right: 0,
  //top: 0,
  //bottom: 0
};

styles.content = {
  ...styles.fill,
  top: "40px",
  textAlign: "center"
};

styles.nav = {
  padding: 0,
  margin: 0,
  position: "absolute",
  top: 0,
  height: "40px",
  width: "100%",
  display: "flex"
};
*/


export default App;


/*
import React, { Component } from 'react';
import './App.scss';
import Home from '../Home/Home';
import data from '../Data/Data'
import { CSSTransition } from "react-transition-group";

// class component
class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      appearHome: true,
      property: data.properties[0]
    }
  }

  toggleAppear = () => {
    this.setState({
      appearHome: !this.state.appearHome
    })
  }

  nextProperty = () => {
    const newIndex = this.state.property.index+1;
    this.setState({
      property: data.properties[newIndex]
    })
  }

  prevProperty = () => {
    const newIndex = this.state.property.index-1;
    this.setState({
      property: data.properties[newIndex]
    })
  }

  render() {
    const {appearHome, property} = this.state;
    return (
      <div className="App">
        <button onClick={() => this.toggleAppear()}>Appear: {`${appearHome}`}</button>
        <button onClick={() => this.nextProperty()} disabled={property.index === data.properties.length-1}>Next</button>
        <button onClick={() => this.prevProperty()} disabled={property.index === 0}>Prev</button>

        <CSSTransition
          in={appearHome}
          appear={true}
          timeout={300}
          classNames="fade"
        >
          <Home property={property} />
        </CSSTransition>
      </div>
    );
  }
}

export default App;
*/


/* portofolio
import React from 'react';
import ReactDom from 'react-dom';

//import {Router, Route, Link, hashHistory} from 'react-router';  ** from scb_porfolio => <Router history={hashHistory}>
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from '../Home/Home.js'
import ProjectPage from '../ProjectPage/ProjectPage.js';


/*-- ROUTES  ---------------------------------------------------------------------*
class App extends React.Component { 
  newRoute(p1, p2) {
      //console.log(p1, p2);
      window.scrollTo(0,0);
  }
  render() {
      return (
          <section>
              <Router>
                <Switch>
                  <Route path="/" component={Home} onEnter={this.newRoute} />
                  <Route path="/projet" component={Home} onEnter={this.newRoute} />
                  <Route path="/projet/:idProjet" Home={ProjectPage} onEnter={this.newRoute} />
                  <Route path="*" component={Home} onEnter={this.newRoute} />
                </Switch>
              </Router>
          </section>
      );
  }
}

export default App;
*/


/* first transition group
import React, { Component } from 'react';

import './App.scss';
import '../../styles/settings/_colors.scss';

import NavLink from '../NavLink/NavLink';
import HSL from '../Test/HSL';
import RGB from '../Test/RGB';


//import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";


//const AnimationExample = () => (
class App extends React.Component {  

  constructor(props){
    super(props);
  }


  render() {
    return (
      <Router>
        <Route
          render={({ location }) => (
            <div style={styles.fill}>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/hsl/10/90/50" />}
              />


              <ul style={styles.nav}>
                <NavLink to="/hsl/10/90/50">Red</NavLink>
                <NavLink to="/hsl/120/100/40">Green</NavLink>
                <NavLink to="/rgb/33/150/243">Blue</NavLink>
                <NavLink to="/rgb/240/98/146">Pink</NavLink>
              </ul>

              <div style={styles.content}>
                <TransitionGroup>
                  {/* no different than other usage of
                  CSSTransition, just make sure to pass
                  `location` to `Switch` so it can match
                  the old location as it animates out
              *  
              }
                  <CSSTransition
                    in={true}
                    appear={true}
                    key={location.key}
                    classNames="fade"
                    timeout={300}
                  >
                    <Switch location={location}>
                      <Route exact path="/hsl/:h/:s/:l" component={HSL} />
                      <Route exact path="/rgb/:r/:g/:b" component={RGB} />
                      {/* Without this `Route`, we would get errors during
                      the initial transition from `/` to `/hsl/10/90/50`
                  *
                 }
                      <Route render={() => <div>Not Found</div>} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
          )}
        />
      </Router>
    )
  }
}
//);


//toggleDivColor() {
//  var css = (this.props.showHideSidenav === "hidden") ? "show" : "hidden";
//  this.setState({"showHideSidenav":css});
//};



/*

const styles = {};

styles.fill = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0
};

styles.content = {
  ...styles.fill,
  top: "40px",
  textAlign: "center"
};

styles.nav = {
  padding: 0,
  margin: 0,
  position: "absolute",
  top: 0,
  height: "40px",
  width: "100%",
  display: "flex"
};


export default App;

*/

