import React from 'react';
import './Coordinates.scss';


class Coordinates extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            x: 0, 
            y: 0,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };
    }

    
    handleResize() {
      this.setState({ windowWidth: window.innerWidth,
                      windowHeight: window.innerHeight });
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize.bind(this));
      window.addEventListener('mousemove', this._changeCoordinates.bind(this));
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
        window.removeEventListener('mousemove', this._changeCoordinates.bind(this));
    }

    _changeCoordinates(e) { 
        this.setState({ x: e.clientX, y: e.clientY });
    }
    
    
    //<aside className="coordinates" onMouseMove={this._onMouseMoveCoordinates.bind(this)}>
    
    render() {
        const { x, y, windowWidth, windowHeight } = this.state;
        return ( 
            <aside className="coordinates" >
                <span>{ windowWidth }</span>
                x
                <span>{ windowHeight }</span>
                &nbsp;x=<span>{ x }</span> 
                y=<span>{ y }</span>
            </aside>
        )
    }
}

export default Coordinates;

