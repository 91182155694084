import React from 'react';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import ProjectGallery from '../ProjectGallery/ProjectGallery';
import ProjectVideo from '../ProjectVideo/ProjectVideo';

import './Project.scss';


class Project extends React.Component {

    componentDidMount() {
        this.props._calledComponent(`project ${this.props.content.id}`);
    }

    createList = (array) => {
        let list = []
        let arrayLength = array.length;

        for (let i = 0; i < arrayLength; i++) {
            let item = array[i].name;
            list.push(<li key={i}>{item}</li>)
        }
        return list
    }

    render() {
        const content = this.props.content;
        const name = this.props.content.name;
        const link = this.props.content.link;
        const images = this.props.content.images;
        const video = this.props.content.video;

        return (
            <section className="section section--project">
                
                {link !== "" ? (
                    <h1 className="project__title project__title--link"><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></h1>
                ) : (
                    <h1 className="project__title">{name}</h1>
                )}

                <ProjectHeader content={content} />

                {video !== "" &&
                    <ProjectVideo video={video} />
                }
                
                <ProjectGallery gallery={images} />   

            </section>
        )
    }
}

export default Project;











/*
import React from 'react';
import ReactDOM from 'react-dom';
import s from './Project.scss';


class Project extends React.Component {  
    
    render(){
        
        // balise video : https://developer.mozilla.org/en/docs/Web/HTML/Element/video
        
        switch(this.props.contenu.typeMedia){
            case "image":
                return (
                    <article className="projet" id={this.props.contenu.idProjet}>
                        <p className="numeroProjet">{this.props.contenu.numeroProjet}</p>
                        <section className="flexRow">
                            <section className="gaucheProjet">
                                <p className="typeProjet">{this.props.contenu.typeProjet}</p>
                                <p className="descriptionProjet">{this.props.contenu.descriptionProjet}</p>
                            </section>
                            <section className="droiteProjet">
                                <a href={this.props.contenu.lienUrlProjet} style={{ textDecoration: 'none' }} target="_blank"> 
                                    <p> <span className="titreProjet">{this.props.contenu.titreProjet}</span>
                                        <span className="anneeProjet">{this.props.contenu.anneeProjet}</span></p>
                                </a>
                                <a href={this.props.contenu.lienUrlProjet} target="_blank"> 
                                    <img className="imageProjet" src={this.props.contenu.urlMedia}/> 
                                </a>
                            </section>
                        </section>
                    </article>
                );
                
            case "video":
                return (
                    <article className="projet" id={this.props.contenu.idProjet}>
                        <p className="numeroProjet">{this.props.contenu.numeroProjet}</p>
                        <section className="flexRow">
                            <section className="gaucheProjet">
                                <p className="typeProjet">{this.props.contenu.typeProjet}</p>
                                <p className="descriptionProjet">{this.props.contenu.descriptionProjet}</p>
                            </section>
                            <section className="droiteProjet">
                                <a href={this.props.contenu.lienUrlProjet} style={{ textDecoration: 'none' }} target="_blank">    
                                    <p> <span className="titreProjet">{this.props.contenu.titreProjet}</span>
                                        <span className="anneeProjet">{this.props.contenu.anneeProjet}</span></p>
                                </a>
                    
                                {/* http://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react *}
                                <video  src={this.props.contenu.urlMedia} 
                                        className="videoProjet" 
                                        controls 
                                        poster={this.props.contenu.posterMedia}
                                        type="video/mp4">
                                </video> 
                                
                            </section>
                        </section>
                    </article>
                );
        
            case "recherche":
                return (
                    <article className="projet" id={this.props.contenu.idProjet}>
                        <p className="numeroProjet">{this.props.contenu.numeroProjet}</p>
                        <section className="flexRow">
                            <section className="gaucheProjet">
                                <p className="typeProjet">{this.props.contenu.typeProjet}</p>
                                <p className="descriptionProjet">{this.props.contenu.descriptionProjet}</p>
                            </section>
                            <section className="droiteProjet">  
                                <a href={this.props.contenu.lienUrlProjet} style={{ textDecoration: 'none' }} target="_blank">
                                    <p> <span className="titreProjet">{this.props.contenu.titreProjet}</span>
                                        <span className="anneeProjet">{this.props.contenu.anneeProjet}</span></p>
                                </a>
                                <section className="descriptionRecherche"> 
                                    <p>
                                        À mi-chemin entre un site traditionnel et une application, le concept – nouveau – 
                                        derrière les Progressive Web Apps (WPA) est d'optimiser la réponse d'un site et ce, 
                                        même s'il n'y a pas de connexion, ou pire, si la connexion est lente (“Lie-Fi”).    
                                    </p>
                                    <p>
                                        On dit alors que le site est développé 'offline first' car celui-ci s'ouvre peu importe 
                                        l'état de la connexion.
                                    </p>
                                    <p>
                                        Comment est-ce possible ?
                                    </p>
                                    <p>
                                        En s'appuyant sur la technologie des 'Service Workers' qu'offrent les navigateurs actuels, 
                                        ceux-ci agissent comme un programme JavaScript autonome qui fait l'intermédiaire entre 
                                        l'appareil et le réseau afin de contrôler, par programmation, la réponse qu'affichera le 
                                        site/application. L'idée est de dire au 'Service Workers' : “Gardes en mémoire ces composants 
                                        (HTML, CSS, JavaScript) jusqu'à ce que je te dise autrement.” Du coup, dès qu'une page du site 
                                        est ouverte, les 'Service Workers' sauvegarderont en cache du navigateur les données spécifiées 
                                        à l'intérieur d'un tableau. Lors de la prochaine visite, ces données seront affichées avec une 
                                        rapidité inégalable puisqu'elles n'attendront pas une réponse du réseau. 
                                    </p>
                                    <img className="imageRecherche" src="../../../medias/cycleReseau.png"/>
                                    <p>
                                        Deux requêtes asynchrones sont donc lancées à l’ouverture d’une page, l'une ultra rapide qui affichera 
                                        les données conservées en cache, et l'autre adressera le réseau. À la réponse du réseau, les données 
                                        différentes seront remplacées. Les 'Service Workers' devient alors le cycle événementiel par 
                                        lequel le site/application sera géré. 
                                    </p>
                                    <p>
                                        Le 'cache' s'installe sur les fonctions appelées en callback de l'évènement 'install' au premier 
                                        lancement d'une page. Lors des prochaines visites, le site reçoit la réponse de la requête faite 
                                        au cache sur l'évènement 'fetch'. S'il y a une différence entre l'ancien et le nouveau cache, 
                                        l'update se fait par les fonctions appelées en callback de l'évènement 'activate'.
                                    </p>
                                        
                                    <img className="imageRecherche" src="../../../medias/cycleSW.png"/>
                                            
                                    <p>
                                        Courte médiagraphie
                                        <br/>
                                        <a href="https://developers.google.com/web/fundamentals/getting-started/primers/service-workers" style={{ textDecoration: 'none' }} className="mediagraphieProjet" target="_blank">
                                            Service Workers: an Introduction        
                                        </a>
                                        <br/>
                                        <a href="https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers" style={{ textDecoration: 'none' }} className="mediagraphieProjet" target="_blank">
                                            Using Service Workers
                                        </a>
                                        <br/>
                                        <a href="https://www.youtube.com/watch?v=cmGr0RszHc8&t=427s" style={{ textDecoration: 'none' }} className="mediagraphieProjet" target="_blank">
                                            Instant Loading: Building offline-first Progressive Web Apps
                                        </a>
                                    </p>
                                
                                </section>
                            </section>
                        </section>
                    </article>
                );

            case "contact":
                return (
                    <article className="projet" id={this.props.contenu.idProjet}>
                        <p className="numeroProjet">{this.props.contenu.numeroProjet}</p>
                        <section className="flexRow">
                            <section className="gaucheProjet">
                                <p className="typeProjet">{this.props.contenu.typeProjet}</p>
                                <section className="listeContact">
                                    <p>Montréal</p>
                                    <p>51 4 52 671 21</p>
                                    <p>simoncbouchard@gmail.com</p>
                                    <a href="https://www.facebook.com/simon.c.bouchard"><img className="logoMS" src="../../../medias/logo_facebook.png"/></a>
                                    <a href="https://www.instagram.com/simonc.bouchard/"><img className="logoMS" src="../../../medias/logo_instagram.png"/></a>
                                    <a href="https://www.youtube.com/user/SimonCBouchard/"><img className="logoMS" src="../../../medias/logo_youtube.png"/></a>
                                    <a href="https://github.com/simoncbouchard"><img className="logoMS" src="../../../medias/logo_git.png"/></a>
                                </section>
                            </section>
                        
                            <section className="droiteProjet">
                                <p> <span className="titreContact">simon c.-bouchard</span></p>
                                
                                <img className="imageContact" src={this.props.contenu.urlMedia}/> 
                            </section>
                        </section>
                    </article>  
                );

             case "bio":
                return (
                    <article className="projet" id={this.props.contenu.idProjet}>
                        <p className="numeroProjet">{this.props.contenu.numeroProjet}</p>
                        <section className="flexRow">        
                            <section className="gaucheBio">
                                <p className="typeBio">{this.props.contenu.typeProjet}</p>
                                <section className="descriptionBio">
                                    <p>
                                        Passionné d'art, de design et d'architecture, mon parcours m'a naturellement amené à 
                                        m'intéresser au langage visuel comme vocabulaire universel. Le pont vers le multimédia 
                                        et l'événementiel a grandement alimenté mes aptitudes à mettre en oeuvre des projets 
                                        créatifs et adaptés à la demande dont je présente ici quelques exemples représentatifs, 
                                        dont ce portfolio développé sur React en ECMAScript 5.
                                    </p>
                                    <p>
                                        Au delà de la technique – nécessaire – il y a l'idée et l'intention communicationnelle. 
                                        Bien que je souscrive rationnellement à la devise 'la forme doit suivre la fonction', 
                                        j'aime ajouté que la forme doit également suivre l'émotion.
                                    </p>
                                </section>
                            </section>
                    
                            <section className="droiteBio">
                                <img className="imageBio" src={this.props.contenu.urlMedia}/>   
                            </section>
                        </section>
                    </article>  
                );
        }
    }
};

export default Project;
*/
