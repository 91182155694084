import React from 'react';

import Project from '../Project/Project';
import Home from '../Home/Home';
//import { CSSTransition, TransitionGroup } from "react-transition-group";

import data from '../Data/Data';


class ProjectDetails extends React.Component { 

    render() {
        const projectName = window.location.pathname.substr(1);
        let isMatched = false;

        for (let key in data) {
            if(data.hasOwnProperty(key)) {
                let projectKey = key;
                if (projectKey === projectName) {
                    isMatched = true;
                }
            }
        }

        if (isMatched) {
            let projectDetails = data[projectName];
            return(
                <Project content={projectDetails} _calledComponent={this.props._calledComponent} />
            )
        } else {
            // call a 404 here
            return(
                <Home _calledComponent={this.props._calledComponent} />
            )
        }
    }
}

export default ProjectDetails;
