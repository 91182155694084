import React from 'react';
import './Burger.scss';


class Burger extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showHideMenu: this.props.showHideMenu };
    }

    render() {
        return (
            <div className={`burger ${this.props.showHideMenu}`} onClick={this.props._toggleMenu}>
                <span className="burger__bullet burger__bullet--1"></span>
                <span className="burger__bullet burger__bullet--2"></span>
                <span className="burger__bullet burger__bullet--3"></span>
                <span className="burger__bullet burger__bullet--4"></span>
                <span className="burger__bullet burger__bullet--5"></span>
                <span className="burger__bullet burger__bullet--6"></span>
                <span className="burger__bullet burger__bullet--7"></span>
                <span className="burger__bullet burger__bullet--8"></span>
                <span className="burger__bullet burger__bullet--9"></span>
            </div>
        )
    }
}

export default Burger;

