import React from 'react';

import './Home.scss';


class Home extends React.Component {

    componentDidMount() {
        this.props._calledComponent("home");
    }

    
    componentWillUnmount() {
        let rectangles = document.querySelectorAll('.home__square');
        for (let i = 0, l =rectangles.length; i < l; i++) {
            rectangles[i].parentNode.removeChild(rectangles[i]);
        }
    }
    

    _onMouseMove(e) {
        const square = document.createElement("div");
        square.setAttribute("class", "home__square");
        document.body.appendChild(square);

        let randomX = Math.floor((Math.random() * 100) + 1);
        let randomY = Math.floor((Math.random() * 100) + 1);
        let randomXpositionFactor = Math.floor(Math.random()*2) == 1 ? 1 : -1;
        let randomYpositionFactor = Math.floor(Math.random()*2) == 1 ? 1 : -1;

        square.style.height = randomY + 'vh';
        square.style.width = randomX + 'vw';
        square.style.left = (e.clientX + randomX) * randomXpositionFactor + 'px';
        square.style.top = (e.clientY + randomY) * randomYpositionFactor + 'px';

        setTimeout(function(){ 
            square.parentNode.removeChild(square);
        }, 1500);
    }


    render() {
        return ( 
            <div className="home" onMouseMove={this._onMouseMove.bind(this)}>

                <section className="home__title">
                    <h1><span className="home__name">simon&nbsp;c_bouchard</span> <span className="home__portfolio">portfolio</span></h1>
                    <div className="home__line"></div>
                    <h2 className="home__portrayal">designer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;front-end&nbsp;developer</h2>
                </section>

            </div>
        )
    }
}

export default Home;





/* portfolio
import React from 'react';
import ReactDOM from 'react-dom';
import Menu from '../Menu/Menu.js';
import s from './Home.scss';


class Home extends React.Component { 
    
    /*-- POSITION SOURIS  ------------------------------------------------------------*
    //http://stackoverflow.com/questions/42182481/getting-mouse-coordinates-in-react
    
    positionSouris(e) {
        this.setState({ x: e.screenX, y: e.screenY });
    }
    
    
    /*-- DIMENSIONS ÉCRAN  -----------------------------------------------------------*
    //https://titobouzout.github.io/react/tips/dom-event-listeners.html
    
    getInitialState() {
        return {largeurFenetre: window.innerWidth,
                hauteurFenetre: window.innerHeight};
    }

    handleResize(e) {
      this.setState({largeurFenetre: window.innerWidth,
                     hauteurFenetre: window.innerHeight});
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render(){
        const { x, y } = this.state;
        
        return (
            <article className="pageAccueil" onMouseMove={this.positionSouris}>
                
                <img className="imageAccueil" src={'../../../medias/accueil.png'}/>

                <Menu /> 
            
                <aside id="coordonnees">
                    <span id="dimensionEcranX">{this.state.largeurFenetre}</span>
                    x
                    <span id="dimensionEcranY">{this.state.hauteurFenetre}</span>
                    x=<span>{ x }</span> 
                    y=<span>{ y }</span>
                </aside>

                <section className="titre">
                    <p><span id="scb">SCB</span> <span id="portfolio">portfolio</span></p>
                    <p id="qualif"><span id="designer">designer</span> <span id="multimedia">multimedia</span></p>
                </section>
            
            </article>   
        )
    }
}

export default Home;
*/