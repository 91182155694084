import React from 'react';

import './ProjectGallery.scss';

  
class ProjectGallery extends React.Component {

    render() {
        let imageList = this.props.gallery;
        let arrayLength = imageList.length;
        let imageFolder = imageList[0].folder;
        let images = [];

        for (let i = 0; i < arrayLength; i++) {
            images.push(imageList[i].name);
        }
        
        let gallery = images.map((image, i) => {
            return <div key={image} className={"project__media__frame project__media__frame--" + i} ><img src={require(`../../assets/projects/${imageFolder}/${image}`)} alt={image} className={"project__media__img project__media__img--" + i} /></div>
        });

        return (
            <div className="project__media project__media--gallery">{gallery}</div>
        )
    }
};


export default ProjectGallery;