import React from 'react';
import NavLink from '../NavLink/NavLink';
import './Menu.scss';


class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showHideMenu: this.props.showHideMenu };
    }

    render() {
        return (
            <div className={`menu ${this.props.showHideMenu}`}>
                <div className="menu__links">
                    <ul className="menu__links__list">
                        <NavLink to="/ombrelle" onClick={this.props._toggleMenu}>ombrelle<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;game</span></NavLink>
                        <NavLink to="/space-square" onClick={this.props._toggleMenu}>space square<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;game</span></NavLink>
                        <NavLink to="/think-tank" onClick={this.props._toggleMenu}>think tank<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;game</span></NavLink>
                        <NavLink to="/equilibrium" onClick={this.props._toggleMenu}>equilibrium<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;game</span></NavLink>
                    </ul>

                    <ul className="menu__links__list">
                        <NavLink to="/mini-putt-91" onClick={this.props._toggleMenu}>mini-putt 91<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;video</span></NavLink>
                        <NavLink to="/cest-agressant" onClick={this.props._toggleMenu}>c'est agressant<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;video</span></NavLink>
                    </ul>
                </div>

                <div className="menu__links">
                    <ul className="menu__links__list">
                        <NavLink to="/lg2" onClick={this.props._toggleMenu}>lg2<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;websites</span></NavLink>
                        <NavLink to="/ward-associes" onClick={this.props._toggleMenu}>Ward & associés<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;website</span></NavLink>
                        <NavLink to="/pullman" onClick={this.props._toggleMenu}>Pullman<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;website</span></NavLink>
                        <NavLink to="/this-portfolio" onClick={this.props._toggleMenu}>this.portfolio<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;website</span></NavLink>
                    </ul>

                    <ul className="menu__links__list">
                        <NavLink to="/flyers" onClick={this.props._toggleMenu}>flyers<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;graphic design</span></NavLink>
                    </ul>
                </div>

                <div className="menu__links">
                    <ul className="menu__links__list">
                        <NavLink to="/tes-pas-game" onClick={this.props._toggleMenu}>t'es pas game<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;event design</span></NavLink>
                        <NavLink to="/gabriel" onClick={this.props._toggleMenu}>gabriel<span className="menu__link__type">&nbsp;&nbsp;-&nbsp;&nbsp;event design</span></NavLink>
                    </ul>

                    <ul className="menu__links__list"> 
                        <li className="menu__link"><a href="https://www.instagram.com/simonc.bouchard/" target="_blank" rel="noopener noreferrer">instagram</a></li>
                        <li className="menu__link"><a href="mailto:simoncbouchard@gmail.com">email</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Menu;

