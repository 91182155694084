import React from 'react';
import { Link } from "react-router-dom";

import './NavLink.scss';

  
const NavLink = (props) => (
    <li className="menu__link">
        <Link {...props} style={{ color: "inherit" }} />
    </li>
);


export default NavLink;